<template>
  <v-container 
  fill-height fluid
  class="background-wallpaper"
  >

    <v-row 
    align="center"
    justify="center"
    >
        <v-col justify="center" align="center">
          <div class="pb-10 pt-4">
            <!-- <v-img
              src="@/assets/img/logo/logo2.png"
              width="100"
            ></v-img> -->
            <v-img
              max-height="270"
              max-width="340"
              class="mr-3"
              :src="logoTemanPlus"
            ></v-img>
            <!-- <h3 class="pt-2 white--text">Total Expressways Maintenance Management System</h3> -->
          </div>

          <h2 class="pt-12 white--text">Choose Account Type</h2>

          <v-col>
            <span class="white--text">To get started, please indicate which type of account is fit your requirements:</span>
          </v-col>

          <v-col>
            <v-hover v-slot="{ hover }">
              <v-btn 
              class="ma-5 cust-button-login" 
              :style= "{ 'color': hover ? 'white' : '#0061aa' }"
              @click="goToLogin('Plus User')"
              >
                <v-icon 
                size="100"
                >mdi-shield-account-outline</v-icon>
                  <div class="extUser pt-3" style="font-size:20px;">
                    PLUS User
                  </div>
              </v-btn>
            </v-hover>
            <!-- <v-btn class="ma-5">
              <v-icon x-large>mdi-account
                <div class="plusUser">
                  External User
                </div>
              </v-icon>
            </v-btn> -->
            <v-hover v-slot="{hover}">
              <v-btn
              class="ma-5 cust-button-login" 
              :style= "{'color' : hover? 'white' : '#0061aa'}"
              @click="goToLogin('External User')">
                <v-icon 
                size="100" 
                >mdi-account-outline</v-icon>
                  <div class="extUser pt-3" style="font-size:20px;">
                    External User
                  </div>
              </v-btn>
            </v-hover>

          </v-col>

          <v-col class="pt-12">
            <span class="white--text">For any inquiries, please contact <br>helpdesk@plus.com.my</span>
          </v-col>

          
        </v-col>

    </v-row>

  </v-container>
</template>

<script>
// -- import something here --

export default {

  data: () => ({

    hover: false,
    logoTemanPlus: require("@/assets/img/logo/temanpluslogo.png"),

    
  }),

  mounted() {

    // console.log("aaaaaaaaaa");
    
  },

  methods: {

    goToLogin(data)
    {
      // console.log(data);
      if(data == "Plus User")
      {
        let url = "";

        if(location.href.includes('localhost'))
        {
          url = "http://localhost:8080/Loading"
        }
        else if(location.href.includes('serasi'))
        {
          url = "https://teman.serasi.tech/Loading"
        }
        else if(location.href.includes('plus'))
        {

          // url = "https://teman.plus.com.my/plus/"
          if(location.protocol=='https:')
          {
            url = "https://teman.plus.com.my/plus/"
          }
          else 
          {
            url = "https://temanrf.plus.com.my/plus/"
          }

        }


        console.log(url);
        window.location.href = "https://login.microsoftonline.com/4b4a4c51-ed99-465f-96cd-1a5bc81a8d60/oauth2/authorize?" +
        "response_type=id_token&client_id=01e1005b-a4ec-4b9f-804d-bc77f187631f&redirect_uri="+ url +"&nonce=asdasdasdas";
        
          // window.location.href = "https://login.microsoftonline.com/240e4cb4-e57a-48ad-bc81-b4298c49a8e8/oauth2/authorize?" +
        //   "response_type=id_token&client_id=e6c427b8-8564-4f1b-ad99-41eb84790b7f&redirect_uri=" + url +
        //   "&state=0cda22e9-61ab-40bf-a848-b6a3adcb0cca&client-request-id=fd47424f-ed3d-45a0-9773-a52a8644cabb&x-client-SKU=Js&x-client-Ver=1.0.11&nonce=33cb44ea-2028-410e-acb6-8545897b4d1e";
      }

      else if(data == "External User")
      {
        // console.log(data);
        this.goToPage("/ExternalLogin")
      }
    }
    
  },

};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

.background-wallpaper {
  // background: black;
  background-image: url("~@/assets/img/wallpaper/Main Page.svg") !important;
  background-size:cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
}

// .v-btn:not(.v-btn--round).v-size--default {
//   height: 300px;
//   min-width: 300px;
//   border-radius: 15px;
// }

// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover
// {
//   background: rgb(4,153,75);
//   background: linear-gradient(180deg, rgba(4,153,75,1) 0%, rgba(14,74,42,1) 100%);
// }

.cust-button-login > .v-btn__content
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cust-button-login
{
  background: white;
  color: #0061aa;
  height: 230px !important;
  min-width: 250px !important;
  border-radius: 15px;
}
.cust-button-login:hover
{
  background: rgb(4,153,75);
  background: linear-gradient(180deg, rgba(4,153,75,1) 0%, rgba(14,74,42,1) 100%);
  color: white;
}
.cust-button-login > .v-btn__content > i::after
{
  color: white !important;
}
.cust-icon-login
{
  color: #0061aa !important;
}
.cust-icon-login:hover
{
  color: white !important;
}

// span.plusUser.pt-3
// {
//   color: #0061aa;
// }

// div.extUser.pt-3
// {
//   color: #0061aa !important;
// }

</style>


